<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        {{ $t('type_drug_equipment') }}
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="d-none d-md-block"
          @click="isAddNewDrugType = true"
        >
          <v-icon class="me-1">
            {{ icons.mdiPlus }}
          </v-icon><span> {{ $t("add_type_drug_equipment") }}</span>
        </v-btn>
        <v-btn
          color="primary"
          fab
          icon
          outlined
          class="d-block d-md-none"
          @click="isAddNewDrugType = true"
        >
          <v-icon>
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-row class="px-2">
        <v-col
          cols="12"
          md="6"
          class="py-0"
          lg="4"
        >
          <v-text-field
            v-model="searchtext"
            :label="$t('search')"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
          class="py-0"
        >
          <v-select
            v-model.trim="drug_category_id"
            :items="categories"
            :label="$t('category')"
            :item-text="$i18n.locale"
            outlined
            dense
            item-value="value"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="py-0"
          lg="4"
        >
          <v-select
            v-model.trim="drugtype_status_id"
            :items="statusList"
            :label="$t('status')"
            :item-text="$i18n.locale"
            outlined
            dense
            item-value="value"
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.drugtype_id`]="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:[`item.drugtype_category_id`]="{ item }">
          <DrugStatus :type="item.drugtype_category_id" />
        </template>
        <template v-slot:[`item.drugtype_status_id`]="{ item }">
          <StatusBlock :status="item.drugtype_status_id" />
        </template>
        <template v-slot:[`item.actions`]="{ item}">
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                @click="dataEdit = item,isEditDrugType = true"
                v-on="on"
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            {{ $t("edit") }}
          </v-tooltip>
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                :disabled="item.drugtype_active_id=='2'"
                v-bind="attrs"
                @click="drugtype_id = item.drugtype_id;drugtype_status_id_update = item.drugtype_status_id;isStatusUpdate = true"
                v-on="on"
              >
                <v-icon>{{ item.drugtype_status_id==1? icons.mdiDeleteOutline:icons.mdiCheck }}</v-icon>
              </v-btn>
            </template>
            {{ item.drugtype_status_id==1? $t("suspend"):$t('normal') }}
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="isStatusUpdate"
      persistent
      max-width="350"
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon
            color="warning"
            class="me-1"
          >
            {{ icons.mdiAlertOutline }}
          </v-icon>  {{ drugtype_status_id_update==1?$t('confirmDrugTypeSuspend'):$t('cancelDrugTypeSuspend') }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            :loading="processUpdateStatus"
            :disabled="processUpdateStatus"
            @click="updateStatus"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="isStatusUpdate = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AddNewDrugType
      v-model="isAddNewDrugType"
      @onAdd="fetchDataTable"
    />

    <EditDrugType
      v-model="isEditDrugType"
      :data-edit="dataEdit"
      @onUpdate="fetchDataTable"
    />
  </div>
</template>
<script>
import {
  mdiPlus, mdiPencilOutline, mdiDeleteOutline, mdiCheck, mdiAlertOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import useDrugType from './useDrugType'
import StatusBlock from '@/components/basicComponents/StatusBlock.vue'
import DrugStatus from '@/components/basicComponents/DrugStatus.vue'
import AddNewDrugType from './AddNewDrugType.vue'
import EditDrugType from './EditDrugType.vue'

export default {
  components: {
    StatusBlock,
    DrugStatus,
    AddNewDrugType,
    EditDrugType,
  },

  setup() {
    const isAddNewDrugType = ref(false)
    const dataEdit = ref({})
    const isEditDrugType = ref(false)
    const {
      isStatusUpdate,
      drugtype_id,
      drugtype_status_id_update,
      processUpdateStatus,
      updateStatus,
      columns,
      searchtext,
      drug_category_id,
      drugtype_status_id,
      dataTableList,
      loading,
      options,
      statusList,
      fetchDataTable,
      categories,
    } = useDrugType()

    return {
      isStatusUpdate,
      drugtype_id,
      drugtype_status_id_update,
      processUpdateStatus,
      updateStatus,
      dataEdit,
      isEditDrugType,
      isAddNewDrugType,
      fetchDataTable,
      columns,
      searchtext,
      drug_category_id,
      drugtype_status_id,
      dataTableList,
      loading,
      options,
      statusList,
      categories,
      icons: {
        mdiPlus, mdiPencilOutline, mdiDeleteOutline, mdiCheck, mdiAlertOutline,
      },
    }
  },

}
</script>
