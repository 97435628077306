import { onMounted, ref, watch } from '@vue/composition-api'
import basicStatus from '@/@fake-db/data/basicStatus.json'
import category_drug_type_for_search from '@/@fake-db/data/category_drug_type_for_search.json'
import drugType from '@/api/DrugManagement/drugType'
import { i18n } from '@/plugins/i18n'
import store from '@/store'

export default function useDrugType() {
  const searchtext = ref('')
  const drug_category_id = ref('')
  const statusList = ref(basicStatus.data)
  const categories = ref(category_drug_type_for_search.data)
  const drugtype_status_id = ref(1)
  const dataTableList = ref([])
  const loading = ref(false)
  const options = ref({})
  const columns = ref([
    {
      text: '#',
      align: 'start',
      sortable: false,
      value: 'drugtype_id',
      width: 30,
    },
    {
      text: i18n.t('type'),
      value: 'drugtype_name',
      width: 200,
    },
    {
      text: i18n.t('category'),
      value: 'drugtype_category_id',
      align: 'center',
      width: 150,
    },
    {
      text: i18n.t('status'),
      value: 'drugtype_status_id',
      align: 'center',
      width: 150,
    },
    {
      text: i18n.t('option'),
      value: 'actions',
      align: 'center',
      width: 150,

    },
  ])

  const isStatusUpdate = ref(false)
  const drugtype_id = ref('')
  const drugtype_status_id_update = ref('')
  const processUpdateStatus = ref(false)

  const { drugTypeList, drugTypeStatusUpdate } = drugType

  onMounted(() => {
    fetchDataTable()
  })

  const updateStatus = () => {
    processUpdateStatus.value = true
    drugTypeStatusUpdate({
      drugtype_id: drugtype_id.value,
      drugtype_status_id: drugtype_status_id_update.value == '1' ? '2' : '1',
    }).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      isStatusUpdate.value = false
      processUpdateStatus.value = false
      fetchDataTable()
    })
  }

  const fetchDataTable = () => {
    loading.value = true
    drugTypeList({
      searchtext: searchtext.value,
      drug_category_id: drug_category_id.value,
      drugtype_status_id: drugtype_status_id.value,
    }).then(res => {
      dataTableList.value = res.data
      options.value.itemsPerPage = -1
      options.value.page = 1
      loading.value = false
    })
  }

  watch([searchtext, drug_category_id, drugtype_status_id], () => {
    fetchDataTable()
  })

  return {
    isStatusUpdate,
    drugtype_id,
    drugtype_status_id_update,
    processUpdateStatus,
    updateStatus,
    columns,
    searchtext,
    drug_category_id,
    drugtype_status_id,
    dataTableList,
    loading,
    options,
    statusList,
    categories,
    fetchDataTable,
  }
}
